<template>
  <div
      data-tw-class="md:flex fixed right-0 md:top-[250.5px] lg:top-[350.5px] top-[200.0px] z-50 flex-col sidebar_buttons"
      class="buttonsContainerClass"
      :style="{ backgroundColor: buttonColor, borderColor: lightenDarkenColor(buttonColor, -50), borderRadius: '10px 0 0 10px', maxWidth: '100px'}"
  >
    <div
        v-for="(link, index) in links"
        :key="index"
        data-tw-class="cursor-pointer leading-6 text-decoration-line[none] flex justify-center items-center px-2"
        class="button"
        :class="buttonClasses(index)"
        :style="{
          backgroundColor: index === 0 ? buttonColor : '',
          color: textColor,
          height: buttonHeight(index),
          width: buttonWidth(),
          borderColor: index !== 0 ? lightenDarkenColor(buttonColor, -20) : 'transparent',
          ':hover': { backgroundColor: lightenDarkenColor(buttonColor, -10) }
        }"
        @click="handleClick(link.action)"
    >
      <a
          :href="link.type === 'url' ? link.action : ''"
          class="linkClass"
          data-tw-class="no-underline flex items-center space-x-2"
      >
        <i
            v-if="link.icon"
            :class="link.icon"
        />
        <p
            class="textClass"
            data-tw-class="uppercase text-xs md:text-sm lg:text-lg"
        >
          {{ link.text }}
        </p>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'SidebarButtons',
  props: ['widgetId'],
  data() {
    return {
      links: [], // This will hold the links fetched from the API
      buttonColor: '', // Hex color for button background
      textColor: '' // Hex color for text
    };
  },
  mounted() {
    this.fetchLinks();
  },
  methods: {
    async fetchLinks() {
      let rawApiResponse = await axios.get(process.env.VUE_APP_API_URL + '/api/widget/' + this.widgetId);
      let response = rawApiResponse.data;
      // Mocking an API call
      this.buttonColor = response.button_colour; // Example: a green color
      this.textColor = response.text_colour; // Example: white for text
      this.links = response.links;
    },
    handleClick(action) {
      if (typeof action === 'string' && action.startsWith('javascript:')) {
        eval(action.substring(11)); // Executes the JavaScript code
      }
    },
    lightenDarkenColor(col, amt) {
      let usePound = false;
      if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
      }
      let num = parseInt(col, 16);
      let r = (num >> 16) + amt;
      let b = ((num >> 8) & 0x00FF) + amt;
      let g = (num & 0x0000FF) + amt;
      return (usePound ? "#" : "") + ((g | (b << 8) | (r << 16)).toString(16).padStart(6, '0'));
    },
    buttonClasses(index) {
      return [
        'md:w-22 md:h-[190px]', // Medium screens
        'lg:w-28 lg:h-[198px]', // Large screens
        {
          'rounded-tl-[25px] rounded-tr-[25px]': index === 0,
          'rounded-bl-[25px] rounded-br-[25px]': index === this.links.length - 1,
          'border-t': index !== 0
        }
      ];
    },
    buttonHeight(index) {
      // Return a dynamic height based on the index
      if (index === 0) return '148px'; // First button
      if (index === this.links.length - 1) return '148px'; // Last button
      return '120px'; // Middle buttons
    },
    buttonWidth() {
      return '100%'; // Full width of the parent container
    }
  }
};
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}

.button {
  cursor: pointer; /* cursor-pointer */
  line-height: 1.5rem; /* leading-6 */
  text-decoration-line: none; /* text-decoration-line[none] */
  display: flex; /* flex */
  justify-content: center; /* justify-center */
  align-items: center; /* items-center */
  padding-left: 0.5rem; /* px-2 */
  padding-right: 0.5rem; /* px-2 */
}

.buttonsContainerClass {
  display: flex; /* flex */
  flex-direction: column; /* flex-col */
  position: fixed; /* fixed */
  right: 0px; /* right-0 */
  top: 200px; /* top-[200.0px] */
  z-index: 50; /* z-50 */
}

.linkClass {
  text-decoration: none; /* no-underline */
  display: flex; /* flex */
  align-items: center; /* items-center */
  gap: 0.5rem; /* space-x-2 */
}

.textClass {
  text-transform: uppercase; /* uppercase */
  font-size: 0.75rem; /* text-xs */
  line-height: 1rem; /* text-xs */

}

@media (min-width: 768px) {
  .buttonsContainerClass {
    top: 250.5px; /* md:top-[250.5px] */
    display: flex; /* md:flex */
  }

  .textClass {
    font-size: 0.875rem; /* md:text-sm */
    line-height: 1.25rem; /* md:text-sm */
  }
}

/* Apply these styles only on large screens and up */
@media (min-width: 1024px) {
  .buttonsContainerClass {
    top: 350.5px; /* lg:top-[350.5px] */
  }

  .textClass {
    font-size: 0.8rem; /* lg:text-lg */
    line-height: 1.25rem; /* lg:text-lg */
  }
}
</style>
