import { createApp } from 'vue'
import App from './App.vue'
// import { createPinia } from 'pinia';
// import CartService from '@/services/CartService';
// import { registerPageComponents } from './registerPageComponents';
// import ProductService from "@/services/ProductService"; // Import the function
import '@/styles.scss';
console.log("Master Script Loading....")

if (widgetConfig) {
    widgetConfig.locations.forEach(location => {
        let app = createApp(App);

        // Provide the location data to the app
        app.provide('location', location);

        // Check if location div already exists
        let locationDiv = document.querySelector(`#location-${location.id}`);

        // If location div doesn't exist, create a new one
        if (!locationDiv) {
            locationDiv = document.createElement('div');
            locationDiv.id = `location-${location.id}`;
            document.body.appendChild(locationDiv);
        }

        locationDiv.classList.add('tailwind');  // Adding the 'tailwind' class

        // Loop through widgets in the location and dynamically load components
        location.widgets.forEach(widget => {
            const widgetComponent = require(`./components/widgets/${widget.id}/widget.vue`).default;
            app.component(`widget-${widget.id}`, widgetComponent);
        });

        // Mount the app at the location's div
        app.mount(`#location-${location.id}`);
    });
}

// if (ecommerceConfig) {
//     let cartService = new CartService(ecommerceConfig);
//     let productService = new ProductService(ecommerceConfig);
//
//     const pina = createPinia()
//
//     // Lets setup the app and pina
//     let app = createApp(App);
//     app.use(pina)
//     app.provide('render', ecommerceConfig.pageRender)
//     app.provide('config', ecommerceConfig)
//     app.provide('cartService', cartService);
//     app.provide('productService', productService);
//     cartService.initCart();
//
//     // Check if location div already exists
//     let locationDiv = document.querySelector(`#ec-${ecommerceConfig.pageRender.location}`);
//
//     // If location div doesn't exist, create a new one
//     if (!locationDiv) {
//         locationDiv = document.createElement('div');
//         locationDiv.id = `ec-${ecommerceConfig.pageRender.location}`;
//         document.body.appendChild(locationDiv);
//     }
//
//     registerPageComponents(app)
//     app.mount(`#ec-${ecommerceConfig.pageRender.location}`);
// }
